import React from 'react';
import PropTypes from 'prop-types';

export const baseUrlDemo = "https://api.ezfrontend.com";


// export const baseUrl = "https://sv1.manhbf.site/api"


// export const baseUrl = "http://127.0.0.1:8000/api";
export const baseUrl = "https://lythevinh.duckdns.org/api";

