import React from 'react';
import PropTypes from 'prop-types';

import "./style.scss"

GlobalStyles.propTypes = {

};

function GlobalStyles({ children }) {
    return children
}

export default GlobalStyles;